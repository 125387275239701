import navUtil from "@/components/global/CustomizeNav/util";
import {
  getResuList,
  getResuListFile,
  payInfo
} from "./api";
import Util from "@/utils/utils";
export default {
  data() {
    return {
      isPayLoading:false,
      isPayhLoading:false,
      dataList: [],
      navList: [],
      isChange: false,
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },

      {
        path: "/marketActivityResult",
        name: "",
        title: "认购结果"
      }
      ],
      total: 0,
      showTotalColumns: ['抢购数量', '已下单数量',],
      tcolumns: [
        {
          label: "参与编码",
          prop: "orderCode",
          key: "orderCode",
          width: 200
        },
        {
          label: "活动编码",
          prop: "actProactivityCode",
          sortable: 'custom',
          sortName: "activityId.activityCode",
          width: 200
        },
        {
          label: "活动名称",
          prop: "actProactivityName",
          width: 250
        },
        // 活动来源未确定
        {
          label: "活动来源",
          prop: "sourceName",
          width: 250
        },
        {
          label: "活动类型",
          prop: "actProactivityType",
          width: 200
        },
        {
          label: "促销方式",
          prop: "actProdiscountTypeName",
          width: 250
        },
        {
          label: "抢购时间",
          prop: "activityTime",
          key: "activityTime",
          width: 200
        },
        {
          label: "抢单支付有效期",
          prop: "purchasePaymentTimeLimitStr",
          key: "purchasePaymentTimeLimitStr",
          ellipsis: true,
          width: 350
        },
        {
          label: "开始时间",
          prop: "actProstartDate",
          width: 250
        },
        {
          label: "结束时间",
          prop: "actProendDate",
          width: 250
        },
        {
          label: "状态",
          prop: "statusName",
          key: "statusName",
          ellipsis: true,
          width: 250
        },
        {
          label: "产品型号",
          prop: "actProproductInfoZzprdmodel",
          width: 200,

        },
        {
          label: "标准价格",
          prop: "actProstandardPrice",
          width: 250
        },
        {
          label: "开票单价",
          prop: "billPrice",
          width: 250
        },
        {
          label: "抢购数量",
          prop: "buyNum",
          width: 250
        },

        {
          label: "已下单数量",
          prop: "transNum",
          width: 250
        },
        {
          label: "本次返利金额",
          prop: "rebateMoney",
          key: "rebateMoney",
          ellipsis: true,
          width: 250
        },

        {
          label: "本次返利金额合计",
          prop: "rebateMoneyTotal",
          key: "rebateMoneyTotal",
          ellipsis: true,
          width: 350
        },
        {
          label: "CIS编码",
          prop: "cisCode",
          key: "cisCode",
          ellipsis: true,
          width: 250
        },
        {
          label: "MDM编码",
          prop: "mdmCode",
          key: "mdmCode",
          ellipsis: true,
          width: 250
        },
        {
          label: "商家名称",
          prop: "custInfoName",
          key: "custInfoName",
          ellipsis: true,
          width: 250
        },
        {
          label: "抢购账号",
          prop: "account",
          key: "account",
          ellipsis: true,
          width: 250
        },
        // {
        //   label: "抢购时间",
        //   prop: "activityTime",
        //   key: "activityTime",
        //   ellipsis: true
        // },
        // 转单状态
        // {
        //   label: "转单状态",
        //   prop: "rebateMoney",
        //   key: "rebateMoney",
        //   ellipsis: true
        // },
        {
          label: "定金",
          prop: "deposit",
          key: "deposit",
          align: 'right',
          ellipsis: true,
          width: 250
        },
        {
          label: "定金合计",
          prop: "depositSum",
          key: "depositSum",
          align: 'right',
          ellipsis: true,
          width: 250
        },
        // // 22
        // {
        //   label: "是否有效",
        //   prop: "status",
        //   key: "status",
        //   align: 'right',
        //   ellipsis: true
        // },
        // 22
        {
          label: "作废原因",
          prop: "invalidReason",
          key: "invalidReason",

          ellipsis: true,
          width: 250
        },
        {
          label: "主单号",
          prop: "mainOrderCode",
          key: "mainOrderCode",
          ellipsis: true,
          width: 250
        },
        {
          label: "组合编码",
          prop: "packageCode",
          key: "packageCode",
          ellipsis: true,
          width: 250
        }
      ],

      tableData: [],
      tabelIsLoading: false,
      //x 最大宽度 y 最大高度
      scroll: {
        x: 3400
      },
      modelDataConfirm: [], // 查询结果
      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 0,
        sortName: "",
        sortOrder: "",
      },
      loading: false,
      params: {
        type: ""
      },
      selRows: [],
      expLoading: false,
      fx: false, //只展示分销商
      onlyMy: true //只展示我的
    };

  },
  mounted() {
    //导航数据
    this.navList = navUtil.orderList;
  },
  methods: {
    // 合计
    getSummariesFun(param) {

      // const showTotalColumns = []//控制显示的列
      const {
        columns,
        data
      } = param;
      let result = [];
      let obj = {};
      for (let i = 0; i < data.length; i++) {
        if (!obj[data[i].documentNum]) {
          result.push(data[i]);
          obj[data[i].documentNum] = true;
        }
      }

      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        if (!this.showTotalColumns.includes(column.label)) {
          return;
        }

        if (column.label == '定金' || column.label == '定金合计' || column.label == '本次返利金额' || column.label == '本次返利金额合计') {
          const values = result.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            // sums[index]= sums[index].toFixed(2)

            sums[index] = sums[index].toFixed(2)
            sums[index] += " "; //单位

          } else {
            sums[index] = ""; //计算存在nan单位
          }
        } else {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            // sums[index]= sums[index].toFixed(2)
            sums[index] = sums[index].toFixed(2)
            sums[index] += " "; //单位
          } else {
            sums[index] = ""; //计算存在nan单位
          }
        }
      });

      return sums;
    },
    // 组合支付
    gopayZH(){
      let data = []
      for (let i=0;i<this.selRows.length;i++) {
        if (this.selRows[i].actProactivityType == '抢购') {
          this.$message.warning('活动类型为抢购的订单，不能进行此操作!')
          return
        }
        // 0未转单  1已转订单 2转单中 3待付款 4等待支付结果 11 部分转单
        if (this.selRows[i].transFlag !== 3) {
          this.$message.warning('只能选择待付款的订单!')
          this.isPayhLoading = false
          return
        }
        data.push({
          id:this.selRows[i].id
        })
      }
      this.$router.push({
        path:'/combinedOrderSettlement',
        query:{
          listOder:JSON.stringify(data),
          page:'acivityResultPage'
        }
      })



    },
    // 去付钱
    gopay() {
      this.isPayLoading = true
      if (this.selRows.length != 1) {
        this.$message.warning('请选择一条认购记录!')
        this.isPayLoading = false
        return false
      }
      if (this.selRows[0].actProactivityType == '抢购') {
        this.$message.warning('活动类型为抢购的订单，不能进行此操作!')
        this.isPayLoading = false
        return false
      }
      // 0未转单  1已转订单 2转单中 3待付款 4等待支付结果 11 部分转单
      if (this.selRows[0].transFlag !== 3) {
        this.$message.warning('只能选择待付款的订单!')
        this.isPayLoading = false
        return false
      }
      let id = this.selRows.map(it => it.id).join(',')
      let code = this.selRows.map(it => it.orderCode).join(',')
      let data = {
        id: id
      }
      payInfo(data).then(res => {

        if (res.data.code == '0') {
          // 如果接口返回参数payType并且为unionPay 直接打开该页面去电子收银台页面 不走之前流程
          if(res.data.payType && res.data.payType=='unionPay' ) {
            // window.open(res.data.payInfo,' _self')
            window.location.href=res.data.payInfo;
            this.isPayLoading = false
            return
          }
          this.isPayLoading = false
          this.$router.push({
            path: '/wxPay',
            query: {
              payInfo: res.data.payInfo,
              code: code
            }
          })


        } else {
          this.$message.warning(res.data.msg)
          this.isPayLoading = false
        }
      })
    },
    checkSelectable(row) {
      // 状态 0 ，有效，  transFlag ： 0未转单 11 部分转单  <transferExpireDateDesc 转单有效期
      // 判断为true才可操作
      return (row.status == 0 && (row.transFlag == 0 || row.transFlag == 11|| row.transFlag == 3) && Date.parse(new Date()) < new Date(row.transferExpireDateDesc).getTime())
    },
    downloadFile(blob, name) {
      var reader = new FileReader();
      reader.readAsDataURL(blob); // 转换为base64，可以直接放入a表情href
      reader.onload = function (e) {
        // 转换完成，创建一个a标签用于下载
        var a = document.createElement("a");
        a.download = name;
        a.href = e.target.result;
        $("body").append(a); // 修复firefox中无法触发click
        a.click();
        $(a).remove();
      }
    },
    exportFile() {
      this.expLoading = true;
      let data = {
        pageNo: 1,
        pageSize: 300000,
        orderByName: this.pager.sortName,
        orderByType: this.pager.sortOrder,
        ...this.params
      };
      getResuListFile(data).then(res => {
        Util.blobToJson(res.data).then(content => {
          if (content && content.code == 'success') {
            this.$message.success('下载成功，请到下载中心查看!')
          }
        }).catch(err => {
          Util.downloadFile(res.data, '认购参与结果.xls')
        }).finally(() => {
          this.expLoading = false;
        })
      }).catch(err => {
        this.expLoading = false;
      });
    },
    rowSelection(rows) {
      this.selRows = rows
    },
    inStock(e) {
      if (e.target.checked == true) {
        this.params.type = "";
        this.fx = false;
        this.onlyMy = true;
      } else {
        this.params.type = "FX";
        this.onlyMy = false;
        this.fx = true;
      }
      this.pager.pageNo = 1;
      this.getData();
    },
    onFineshedchange(e) {
      if (e.target.checked == true) {
        this.params.type = "FX";
        this.onlyMy = false;
        this.fx = true;
      } else {
        this.params.type = "";
        this.fx = false;
        this.onlyMy = true;
      }
      this.pager.pageNo = 1;
      this.getData();
    },
    //排序change事件
    onTableChange(sorter) {
      const sortName = this.tcolumns.filter(it => it.prop == sorter.prop)
      if (sortName.length < 1) {
        return
      }
      if (sorter && sorter.order) {
        this.pager.sortOrder =
          sorter.order == "ascending" ?
            "asc" :
            sorter.order == "descending" ?
              "desc" :
              "";
        this.pager.sortName = sortName[0].sortName;
      } else {
        this.pager.sortOrder = "";
        this.pager.sortName = "";
      }
      this.getData();
    },
    getData() {
      this.tabelIsLoading = true;
      let data = {
        pageNo: this.pager.pageNo,
        pageSize: this.pager.pageSize,
        orderByName: this.pager.sortName,
        orderByType: this.pager.sortOrder,
        ...this.params
      };
      getResuList(data).then(res => {
        this.tabelIsLoading = false;
        res.data.list = res.data.list || []
        res.data.list.forEach(it => {
          it.actProactivityCode = it.actPro.activityCode
          it.actProactivityName = it.actPro.activityName
          it.actProfwOrgName = it.actPro.fwOrgName
          it.actPromatklName = it.actPro.matklName
          it.actProproductInfoZzprdmodel = it.actPro.productInfoZzprdmodel
          it.actProstandardPrice = it.actPro.standardPrice
          it.actProbillPrice = it.actPro.billPrice
          it.actProactivityType = it.actPro.activityType
          it.actProdiscountTypeName = it.actPro.discountTypeName
          it.actProstartDate = it.actPro.startDate
          it.actProendDate = it.actPro.endDate
          it.actProstandardPrice = it.actPro.standardPrice
          it.rebateMoneyTotal = it.buyNum * it.rebateMoney
        })
        this.tableData = res.data.list;
        this.pager.pageNo = res.data.currentPage;
        this.pager.count = res.data.totalRows;
        // this.total = res.data.totalRows;
      });
    },
    pageSearch(pager) {
      this.pager.pageNo = pager.pageNo;
      this.pager.pageSize = pager.pageSize;
      this.getData();
    },
    goOrder() {
      // return
      if (this.selRows.length != 1) {
        this.$message.warning('请选择一条认购记录!')
        return false
      }
      if (this.selRows[0].actProactivityType == '抢购') {
        this.$message.warning('活动类型为抢购的订单，不能进行此操作!')
        return false
      }
      //0未转单  1已转订单 2转单中 3待付款 4等待支付结果 11 部分转单
      if (this.selRows[0].transFlag == 1 || this.selRows[0].transFlag == 2 || this.selRows[0].transFlag == 3 || this.selRows[0].transFlag == 4) {
        this.$message.warning('只能选择未转单或者部分转单的订单!')
        return false
      }


      const userActId = this.selRows.map(it => it.id).join(',')
      const prdIds = this.selRows.map(it => it.actPro.id).join(',')
      const buyNums = this.selRows.map(it => it.buyNum).join(',')
      const isFx = this.selRows.map(it => it.isFenXiao).join(',')
      const mainOrderCode = this.selRows.map(it => it.mainOrderCode).join(',')

      if (isFx == 'N') {
        this.$router.push({
          path: '/confirm/subOrder',
          // channelSubOrder
          query: {
            prdIds: prdIds,
            buyNums: buyNums,
            userActId: userActId,
            mainOrderCode: mainOrderCode,
            isChooseNum: buyNums,
          }
        })
      } else {
        this.$router.push({
          path: '/confirm/channelSubOrder',
          // channelSubOrder
          query: {
            id: userActId,
            type:'rg',
            mainOrderCode: mainOrderCode,
            isChooseNum: buyNums,
          }
        })
      }

    },
    searchFun(arg) {
      this.pager.pageNo = 1
      this.params = {
        ...this.params,
        ...arg[0]
      };
      this.getData();
    }
  }
};