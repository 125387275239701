import http from "@/utils/request";
export function getResuList(data) {
    return http({
      method: "get",
      url: "/marketActivity/queryResultList.nd",
      params:data
    });
  }
  export function getResuListFile(data) {
    return http({
      method: "get",
      url: "/marketActivity/exportResultList.nd",
      params:data,
      responseType: 'blob'
    });
  }

// 跳转支付页面 获取二维码


export function payInfo(data) {
  return http({
    method: "get",
    url: "/marketActivity/payInfo.nd",
    params:data
  });
}
